<template>
    <div class="main-content" style="height:100vh; background: #000000; background-repeat: no-repeat;">
        <div class="position-relative">
            <div class="appBar">
                <a @click="goBack">
                    <img :src="back_icon">
                </a>
                <div class="button-lang">
                <b-link no-gutters @click="openModal" class="dark-card">
                    <flag 
                        :iso="$i18n.locale === 'en' ? 'us' : ($i18n.locale === 'vn' ? 'vn' : 'cn')" 
                        style="width: 20px; height: 20px; border-radius: 16px; margin-left: auto; margin-right: auto;"
                      />
                </b-link>
                </div>
            </div>

            <div class="d-flex justify-content-center align-items-end pt-5">
                <h4 class="mt-5 mx-4 head_style">{{ $t("enter_your_pin") }}</h4>
            </div>
        </div>

        <div class="mainpage pt-4">
            <b-row align-h="center">
                <b-col cols="12">
                    <b-form @submit.prevent="submitForm">

                        

                        <div class="form-group row">
                            <div class="col-sm-12 mb-3">
                                <h5 class="label-form mb-2 text-14">PIN</h5>
                                <div class="input-group">
                                    <input v-for="(digit, index) in digits" :key="index" type="password"
                                        class="form-control label-input" v-model="digits[index]"
                                        @input="updateDigit(index, $event.target.value)" pattern="[0-9]"
                                        required maxlength="1" ref="inputFields" />
                                </div>


                            </div>
                        </div>

                        <b-button type="submit" class="mt-5 mb-5 btn-curved-wallet" variant="primary" block
                            :disabled="isLoading">{{ isLoading ? $t("loading...") : $t("submit") }}</b-button>

                    </b-form>
                </b-col>
            </b-row>
        </div>
        <transition name="modal">
                    <div v-if="showModal" class="modal-backdrop-popup" @click="closeModal">
                        <div class="modal-content-popup" @click.stop>
                            <b-button @click="changeLang('en')" class="w-100 border-popup">
                                <div class="text-white font-weight-bold d-flex text-14">
                                    <i class="header-icon-country"><flag iso="US" /></i
                                    ><span class="ml-2 text-white">{{ $t("en") }}</span>
                                </div>
                            </b-button>
                            <b-button @click="changeLang('zh')" class="w-100 mt-2 border-popup">
                                <div class="text-white font-weight-bold d-flex text-14">
                                    <i class="header-icon-country"><flag iso="CN" /></i
                                    ><span class="ml-2 text-white">{{ $t("zh") }}</span>
                                </div>
                            </b-button>
                            <b-button @click="changeLang('vn')" class="w-100 mt-2 border-popup">
                                <div class="text-white font-weight-bold d-flex text-14">
                                    <i class="header-icon-country"><flag iso="VN" /></i
                                    ><span class="ml-2 text-white">{{ $t("vn") }}</span>
                                </div>
                            </b-button>
                        </div>
                    </div>
                </transition>
        <Dialog ref="msg"></Dialog>
    </div>
</template>
  
<script>
import { checkSecPassword } from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import Dialog from "../../../components/dialog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    computed: {
        ...mapGetters(["lang"]),
        fullPIN() {
            return this.digits.join('');
        }
    },
    components: {
        Dialog,
    },
    data() {
        return {
            back_icon: require("../../../assets/images/etnex_home/etnex_back.png"),
            password: "",
            password_confirmation: "",
            fullname: "",
            email: "",
            contact_number: "",
            myVar: null,
            sending: false,
            path: "",
            showModal: false,
            isLoading: false,
            digits: Array(4).fill('') 
                
        };
    },
    props: ["success"],
    methods: {
        ...mapActions(["signOut"]),
        updateDigit(index, value) {
            // Update the digit at the specified index
            this.digits[index] = value;

            // Move focus to the next input field
            if (index < this.digits.length - 1) {
                this.$refs.inputFields[index + 1].focus();
            }
        },
        goBack() {
            this.signOut();

            this.$router.push("/web/sessions/signIn");
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        changeLang(lang) {
            console.log(lang);
            this.$i18n.locale = lang;
            localStorage.setItem('lan', lang);
            this.closeModal();
        },
        submitForm() {
            let formData = new FormData();
            var self = this;
            formData.append("sec_password", this.fullPIN);
            var result = checkSecPassword(formData);
            self.isLoading = true;

            result
                .then(function (value) {
                    console.log(value.data);
                    if (value.data.error_code == 0) {
                        localStorage.setItem("checkSecPass", 1);
                        self.$refs.msg.makeToast("success", self.$t(value.data.message));
                        setTimeout(() => {
                            var current = location.origin + "/";
                            window.location.href = current + "web";
                        }, 2000);
                    } else {
                        self.$refs.msg.makeToast("danger", self.$t(value.data.message));
                    }
                    self.sending = false;
                    self.isLoading = false;
                })
                .catch(function (error) {
                    self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
                    self.sending = false;
                    self.isLoading = false;
                });
        },
        loadItems() { },
    },
    created() {
        this.loadItems();
        this.username = localStorage.getItem("username");
    },
};
</script>
  
<style lang="scss" scoped>
.main-content {
    max-width: 420px;
    margin: auto;
}

// custom popup
.button-lang {
    position: absolute;
    right: 20%; 
    top: -12px;
}

.modal-backdrop-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(71 71 71 / 50%);
  transition: opacity 0.3s ease;
}

.modal-content-popup {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  transition: transform 0.3s ease;
  transform: scale(1.1);
}

.border-popup{
  border: 1px solid var(--Neon-Blue, #0BDDCC) !important;
}
// .modal-content-popup .close {
//   float: right;
//   cursor: pointer;
// }

.modal-enter-active, .modal-leave-active {
  opacity: 0;
}

.modal-enter-active .modal-content, .modal-leave-active .modal-content {
  transform: scale(0.9);
}

.modal-enter-to, .modal-leave-to {
  opacity: 0;
}

.modal-enter-to .modal-content, .modal-leave-to .modal-content {
  transform: scale(1);
}

.input-group [type=text].form-control{
    height: 58px;
}
</style> 